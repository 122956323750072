/*eslint-disable */

import {bus} from '@/main';

export default {
  name: 'before-booking-message-list',
  components: {},
  props: ['msgPatientListData'],
  data () {
    return {
      patientFullName:''
    }
  },
  computed: {

  },
  mounted () {
    const data = JSON.parse(localStorage.getItem('LoginedUserInfo'))
    if(data){
      this.patientFullName = data.first_name + " " + data.last_name
    }
  },
  methods: {
    messageListOfPatientPopupClosedMethod(){
      bus.$emit('beforeBookingMessageListStateBus', false)
      document.body.classList.remove('popup_open_body')
    },
  }
}


