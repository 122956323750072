import { render, staticRenderFns } from "./make-unfavourite-doctor.html?vue&type=template&id=38dbb017&scoped=true&external"
import script from "./make-unfavourite-doctor.js?vue&type=script&lang=js&external"
export * from "./make-unfavourite-doctor.js?vue&type=script&lang=js&external"
import style0 from "./make-unfavourite-doctor.scss?vue&type=style&index=0&id=38dbb017&prod&scoped=true&lang=scss&external"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "38dbb017",
  null
  
)

export default component.exports