import { render, staticRenderFns } from "./doctor-in-mail-popup.html?vue&type=template&id=74a61017&scoped=true&external"
import script from "./doctor-in-mail-popup.js?vue&type=script&lang=js&external"
export * from "./doctor-in-mail-popup.js?vue&type=script&lang=js&external"
import style0 from "./doctor-in-mail-popup.scss?vue&type=style&index=0&id=74a61017&prod&scoped=true&lang=scss&external"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "74a61017",
  null
  
)

export default component.exports