/*eslint-disable */
import {bus} from '@/main';  
export default {
  name: 'doctor-view-full-info',
  components: {},
  props: ['doctorFullInfoData'],
  data () {
    return {
      selfUserId:''
    }
  },
  computed: {

  },
  mounted () {
  },
  methods: {
    closeDoctorInfoPopup() {
      bus.$emit('doctorFullInfoStateBus', false)
      document.body.classList.remove('popup_open_body')
    },
  }
}