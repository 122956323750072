/*eslint-disable */

import {bus} from '@/main';
import toast from '@/services/toast';

export default {
  name: 'make-unfavourite-doctor',
  components: {},
  props: ['doctorID'],
  data () {
    return {
    }
  },
  computed: {

  },
  mounted () {

  },
  methods: {
    closePopupOfFav() {
      bus.$emit('unFavDoctorPopupStateBus', false)
      document.body.classList.remove('popup_open_body')
    },
    confirmFavAppointment(){
      this.$store.dispatch('makeDoctorUnFavouriteFun', {id:this.doctorID}).then((res) => {
        toast.success(res.data.msg);
        this.$store.dispatch('getDoctorsListApiFun');
        this.closePopupOfFav();
      }).catch((err) => {
        toast.error(err.data.user_msg || err.data.msg);
      })
    },
  }
}